import axios from "axios";

const API_URL = process.env.API_URL || "https://api.dgr.am";
const API_REC_URL =
  process.env.API_REC_URL || "https://ar-recognize.gora.studio";
const MSG_URL =
  process.env.NODE_ENV === "production"
    ? "https://msg.dgr.am"
    : "https://msg.dgr.am";

let Api = {
  v1: axios.create({
    baseURL: API_URL + "/v1/admin"
  }),

  v2: axios.create({
    baseURL: API_URL + "/v2/admin"
  }),

  v2base: axios.create({
    baseURL: API_URL + "/v2"
  }),

  rec: {
    v1: axios.create({
      baseURL: API_REC_URL + "/v1"
    })
  },

  msg: axios.create({
    baseURL: MSG_URL + "/v3"
  }),

  axios,

  setToken(token) {
    this.v1.defaults.headers.common["x-access-token"] = token;
    this.v2.defaults.headers.common["x-access-token"] = token;
    this.v2base.defaults.headers.common["x-access-token"] = token;
    this.msg.defaults.headers.common["x-access-token"] = token;
  }
};

export default {
  install: function(Vue) {
    Vue.prototype.$api = Api;
  }
};
