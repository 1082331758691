import axios from "axios";

const API_URL = process.env.API_URL || "https://api.dgr.am";
const MSG_API_URL = process.env.MSG_API || "wss://msg.dgr.am/v2/ws";
const MSG_URL =
  process.env.NODE_ENV === "production"
    ? "https://msg.dgr.am"
    : "https://msg.dgr.am";

let Api = {
  v1: axios.create({
    baseURL: API_URL + "/v1/admin"
  }),

  v2: axios.create({
    baseURL: API_URL + "/v2/admin"
  }),

  msg: axios.create({
    baseURL: MSG_URL + "/v3"
  })
};

const channels = {
  state: () => ({
    channels: [],
    posts: [],
    filters: {
      channels: {},
      posts: {}
    },
    selectedChannel: 0,
    selectedPosts: []
  }),

  mutations: {
    selectChannel(state, id) {
      if (state.selectedChannel !== id) {
        state.selectedChannel = id;
      } else {
        state.selectedChannel = 0;
      }
    },
    selectPost(state, id) {
      if (state.selectedPosts.includes(id) == true) {
        console.log(state.selectedPosts.includes(id), "🐱‍🐉🐱‍🐉🐱‍🐉");
        state.selectedPosts = state.selectedPosts.filter(message => {
          return message !== id;
        });
      } else {
        console.log(state.selectedPosts.includes(id), "🐱‍🐉🐱‍🐉🐱‍🐉");
        state.selectedPosts.push(Number(id));
      }
    },
    setChannels(state, data) {
      state.channels = data;
    },
    setPosts(state, data) {
      state.posts = data;
    },
    clearPosts(state) {
      state.posts = [];
    },
    clearChannels(state) {
      state.channels = [];
    },
    addPosts(state, data) {
      data.forEach(post => {
        state.posts.push(post);
      });
    }
  },

  actions: {
    fetchChannels({ commit, getters }, query) {
      this._vm.$api.msg
        .get("admin/channel/public/search", {
          params: {
            query,
            limit: 500,
            sn_id: getters.selectedSnId
          }
        })
        .then(res => {
          commit("setChannels", res.data);
        });
    },
    fetchPosts({ commit, getters }, { channel_id } = {}) {
      this._vm.$api.msg
        .get("admin/channel/public/posts", {
          params: {
            channel_id,
            sn_id: getters.selectedSnId,
            back_limit: 20
          }
        })
        .then(res => {
          commit("setPosts", res.data);
        });
    },
    fetchMorePosts({ commit, getters }, { message_id, channel_id }) {
      return new Promise((resolve, reject) => {
        this._vm.$api.msg
          .get("admin/channel/public/posts", {
            params: {
              back_limit: 25,
              message_id,
              channel_id: channel_id ? channel_id : undefined,
              sn_id: getters.selectedSnId
            }
          })
          .then(res => {
            if (res.data.length) {
              commit("addPosts", res.data);
              resolve("posts");
            } else {
              console.log("🤷‍♀️🤷‍♀️🤦‍♂");
              resolve("no_posts");
            }
          });
      });
    },
    fetchCategoryPosts({ commit }, category_id) {
      this._vm.$api.msg
        .get("admin/explore/category", {
          params: { category_id }
        })
        .then(res => {
          commit("setPosts", res.data);
        });
    },
    fetchMoreCategoryPosts({ commit }, args) {
      return new Promise((resolve, reject) => {
        let params = Object();
        params.limit = 25;
        params.category_id = args.category_id;
        params.offset = args.offset;
        console.log(params, "😢😢😢");
        this._vm.$api.msg
          .get("admin/explore/category", {
            params
          })
          .then(res => {
            if (res.data.length) {
              commit("addPosts", res.data);
              resolve("posts");
            } else {
              console.log("🤷‍♀️🤷‍♀️🤦‍♂");
              resolve("no_posts");
            }
          });
      });
    },
    addStats() {},
    makePopular() {}
  },

  getters: {}
};

export default channels;
