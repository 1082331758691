var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "login-page bg-warning h-screen w-100" }, [
    _vm._v("\n  text 123\n")
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }